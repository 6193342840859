// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

//  GET Common LIST
export const commonData = createAsyncThunk('JobCard/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      EmployeeArray: response1.data.body.EmployeeArray,
      allDepartmentArray:response1.data.body.allDepartmentArray
    }
   } else {
   } 
}
})

// Employee Process LIST
export const EmployeeProcesslist = createAsyncThunk('JobCuttingSalaryPayout/EmployeeProcesslist', async (params) => {
  const response = await axios.post(`${api.api_url}/payroll/jobcutting/payout/employeeProcessListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/payroll/jobcutting/payout/employeeProcessList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      EmployeeProcesslist: response1.data.body.EmployeeProcesslist,
      TotalPieces: response1.data.body.TotalPieces,
      TotalAmount: response1.data.body.TotalAmount,
      TotalCount: response1.data.body.TotalCount
    }
   } else {
    return {
      params,
      EmployeeProcesslist: []
    }
   } 
}
})


// SalaryPayout LIST
export const SalaryPayoutlist = createAsyncThunk('JobCuttingSalaryPayout/SalaryPayoutlist', async (params) => {
  const response = await axios.post(`${api.api_url}/payroll/jobcutting/payout/jobCuttingPayoutListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/payroll/jobcutting/payout/jobCuttingPayoutList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      Payoutlist: response1.data.body.PayoutArray
    }
   } else {
   } 
}
})

//saveData Api
export const saveData = createAsyncThunk('JobCuttingSalaryPayout/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/payroll/jobcutting/payout/savePayoutjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/payroll/jobcutting/payout/savePayout`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   } 
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    console.log(error, "error")
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later'
    }    
  }
})

// Edit SalaryPayout
export const editData = createAsyncThunk('JobCuttingSalaryPayout/editData', async (params) => {
  const response = await axios.post(`${api.api_url}/payroll/jobcutting/payout/editPayoutjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/payroll/jobcutting/payout/editPayout`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      EditEmployeeProcesslist: response1.data.body.EmployeeProcesslist,
      CompanyData: response1.data.body.CompanyData
    }
   } else {
    return {
      params,
      EditEmployeeProcesslist: [],
      CompanyData: []
    }
   } 
}
})
// PayOutslipList search
export const PayOutslipList = createAsyncThunk('JobCuttingSalaryPayout/PayoutSlip', async params => {
  const response = await axios.post(`${api.api_url}/payroll/jobcutting/payout/payoutslipListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/payroll/jobcutting/payout/payoutslipList`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        PayoutslipList: response1.data.body.PayslipArray,
        CompanyList: response1.data.body.CompanyArray
      }
    } else {
    }
  }
}
)
//deleteData Api
export const deleteData = createAsyncThunk('JobCuttingSalaryPayout/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/payroll/payout/deleteRatecardjwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/payroll/payout/deleteRatecardjwt`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})
export const JobCuttingSalaryPayoutSlice = createSlice({
  name: 'JobCuttingSalaryPayout',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    RateCartlist:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0,
    EmployeeArray:[],
    allDepartmentArray:[],
    EditEmployeeProcesslist:[],
    CompanyData: [],
    ListEmployee:[],
    EmployeeProcesslist:[],
    TotalCount:0,
    TotalAmount:0.00,
    TotalPieces:0,
    Payoutlist:[],
    PayoutslipList: [],
    CompanyList: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    handleedit: (state, action) => {
      state.editaction = action.payload
    },
    clearstoreData: (state, action) => {
      state.EmployeeProcesslist = action.payload.EmployeeProcesslist
      state.EditEmployeeProcesslist  = action.payload.EditEmployeeProcesslist
      state.CompanyData  = action.payload.CompanyData
      state.TotalCount = action.payload.TotalCount
      state.TotalAmount = action.payload.TotalAmount
      state.TotalPieces = action.payload.TotalPieces
      state.PayoutslipList = action.payload.PayoutslipList 
    },
    clearpayrollstoreData: (state, action) => {
      state.EmployeeProcesslist = action.payload.EmployeeProcesslist
      state.TotalCount = action.payload.TotalCount
      state.TotalAmount = action.payload.TotalAmount
      state.TotalPieces = action.payload.TotalPieces
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(PayOutslipList.fulfilled, (state, action) => {
      state.PayoutslipList = action.payload.PayoutslipList
      state.CompanyList = action.payload.CompanyList
    })
    .addCase(SalaryPayoutlist.fulfilled, (state, action) => {
      state.Payoutlist = action.payload.Payoutlist
      state.params = action.payload.params
    })
    .addCase(editData.fulfilled, (state, action) => {
      state.EditEmployeeProcesslist = action.payload.EditEmployeeProcesslist
      state.CompanyData = action.payload.CompanyData 
      state.params = action.payload.params
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.EmployeeArray = action.payload.EmployeeArray
      state.allDepartmentArray = action.payload.allDepartmentArray
      state.params = action.payload.params
    })
    .addCase(EmployeeProcesslist.fulfilled, (state, action) => {
      state.EmployeeProcesslist = action.payload.EmployeeProcesslist
      state.TotalCount = action.payload.TotalCount
      state.TotalAmount = action.payload.TotalAmount
      state.TotalPieces = action.payload.TotalPieces
    })
  }
})
export const {
  handleStatusFlag, handleedit, clearstoreData, clearpayrollstoreData
} = JobCuttingSalaryPayoutSlice.actions
export default JobCuttingSalaryPayoutSlice.reducer
