// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

//  GET Common LIST
export const commonData = createAsyncThunk('CompanyMaster/commonData', async (params) => {
    const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
     const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        params,
        UserroleArray: response1.data.body.UserroleArray,
        MenuArray:  response1.data.body.menuArray
      }
     } else {
     } 
  }
  })


// User LIST
export const userlist = createAsyncThunk('UserMaster/userlist', async (params) => {
  const response = await axios.post(`${api.api_url}/master/user/userListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/master/user/userList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      userlist: response1.data.body.UserArray
    }
   } else {
   } 
}
})

//saveData Api
export const saveData = createAsyncThunk('UserMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/user/saveUserjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/user/saveUser`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message:'Server not reachable.Please try again later'
    }
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('UserMaster/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/master/user/deleteUserjwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/user/deleteUser`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})

//Reset Data Api
export const ResetData = createAsyncThunk('UserMaster/ResetData', async params => {
  const response = await axios.post(`${api.api_url}/master/user/resetPasswordJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/user/resetPassword`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body.statusFlag,
      message:response1.data.body.message
    }
   } 
  } else {
    return {
      statusFlag:response1.data.body.statusFlag,
      message:response1.data.body.message
    }
   }
})


export const UserMasterSlice = createSlice({
  name: 'UserMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    userlist:[],
    UserroleArray: [],
    MenuArray: [],
    statusFlag:0,
    statuscode:0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(userlist.fulfilled, (state, action) => {
      state.userlist = action.payload.userlist
      state.params = action.payload.params
    })
    .addCase(commonData.fulfilled, (state, action) => {
        state.UserroleArray = action.payload.UserroleArray
        state.MenuArray = action.payload.MenuArray
        state.params = action.payload.params
      })
      .addCase(ResetData.fulfilled, (state, action) => {
        state.message = action.payload.message
       state.statusFlag = action.payload.statusFlag
      })
  }
})
export const {
  handleStatusFlag
} = UserMasterSlice.actions
export default UserMasterSlice.reducer
