// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


//  GET Common LIST
export const commonData = createAsyncThunk('CompanyMaster/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      StateArray: response1.data.body.StateArray
    }
   } else {
   } 
}
})

// Agent LIST
export const Agentlist = createAsyncThunk('AgentMaster/Agentlist', async (params) => {
  const response = await axios.post(`${api.api_url}/master/agent/listAgentjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/master/agent/listAgent`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      AgentList: response1.data.body.AgentList
    }
   } else {
   } 
}
})

//saveData Api
export const saveData = createAsyncThunk('AgentMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/agent/saveAgentjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/agent/saveAgent`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message:'Server not reachable.Please try again later'
    }
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('AgentMaster/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/master/agent/deleteAgentjwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/agent/deleteAgent`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})
export const AgentMasterSlice = createSlice({
  name: 'AgentMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    AgentList:[],
    StateArray:[],
    statusFlag:0,
    statuscode:0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(Agentlist.fulfilled, (state, action) => {
      state.AgentList = action.payload.AgentList
      state.params = action.payload.params
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.StateArray = action.payload.StateArray
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = AgentMasterSlice.actions
export default AgentMasterSlice.reducer
