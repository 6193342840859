// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

// Department LIST
export const DepartmentMasterlist = createAsyncThunk('DepartmentMaster/DepartmentMasterlist', async (params) => {
  const response = await axios.post(`${api.api_url}/employee/department/departmentListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/employee/department/departmentList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      DepartmentMasterlist: response1.data.body.DepartmentArray
    }
   } else {
   } 
}
})

//saveData Api
export const saveData = createAsyncThunk('DepartmentMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/employee/department/saveDepartmentjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/employee/department/saveDepartment`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   } 
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'
    }    
  }
})

//delete Data Api
export const deleteData = createAsyncThunk('DepartmentMaster/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/employee/department/deleteDepartmentjwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/employee/department/deleteDepartment`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})
export const DepartmentMasterSlice = createSlice({
  name: 'DepartmentMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    DepartmentMasterlist:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(DepartmentMasterlist.fulfilled, (state, action) => {
      state.DepartmentMasterlist = action.payload.DepartmentMasterlist
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = DepartmentMasterSlice.actions
export default DepartmentMasterSlice.reducer
