// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

// Designation LIST
export const DesignationMasterlist = createAsyncThunk('DesignationMaster/Designationlist', async (params) => {
  const response = await axios.post(`${api.api_url}/employee/designation/designationListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/employee/designation/designationList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      DesignationMasterlist: response1.data.body.DesignationArray
    }
   } else {
   } 
}
})

//saveData Api
export const saveData = createAsyncThunk('DesignationMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/employee/designation/saveDesignationjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/employee/designation/saveDesignation`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   } 
  } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'
    }    
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('DesignationMaster/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/employee/designation/deleteDesignationjwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/employee/designation/deleteDesignation`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})
export const DesignationMasterSlice = createSlice({
  name: 'DesignationMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    DesignationMasterlist:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(DesignationMasterlist.fulfilled, (state, action) => {
      state.DesignationMasterlist = action.payload.DesignationMasterlist
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = DesignationMasterSlice.actions
export default DesignationMasterSlice.reducer
