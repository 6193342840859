// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


//  GET Common LIST
export const commonData = createAsyncThunk('MachineMaster/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      SelectEmployeeArray: response1.data.body.SelectEmployeeArray,
      EditEmployeeArray:response1.data.body.EditEmployeeArray
    }
   } else {
   } 
}
})

// Machine LIST
export const MachineMasterlist = createAsyncThunk('MachineMaster/MachineMasterlist', async (params) => {
  const response = await axios.post(`${api.api_url}/production/machine/machineListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/production/machine/machineList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      MachineMasterlist: response1.data.body.MachineArray
    }
   } else {
   } 
}
})

//saveData Api
export const saveData = createAsyncThunk('MachineMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/production/machine/saveMachinejwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/machine/saveMachine`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'
    }    
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('MachineMaster/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/production/machine/deleteMachinejwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/machine/deleteMachine`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})
export const MachineMasterSlice = createSlice({
  name: 'MachineMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    MachineMasterlist:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0,
    SelectEmployeeArray:[],
    EditEmployeeArray:[]
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(MachineMasterlist.fulfilled, (state, action) => {
      state.MachineMasterlist = action.payload.MachineMasterlist
      state.params = action.payload.params
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.SelectEmployeeArray = action.payload.SelectEmployeeArray
      state.EditEmployeeArray = action.payload.EditEmployeeArray
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = MachineMasterSlice.actions
export default MachineMasterSlice.reducer
