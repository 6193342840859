// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

// Itemgroup LIST
export const Itemgrouplist = createAsyncThunk('ItemgroupMaster/Itemgrouplist', async (params) => {
  const response = await axios.post(`${api.api_url}/master/itemgroup/itemgroupListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/master/itemgroup/itemgroupList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      Itemgrouplist: response1.data.body.ItemgroupArray
    }
   } else {
   } 
}
})

//saveData Api
export const saveData = createAsyncThunk('ItemgroupMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/itemgroup/saveItemgroupjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/itemgroup/saveItemgroup`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message:'Server not reachable.Please try again later'
    }
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('ItemgroupMaster/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/master/itemgroup/deleteItemgroupjwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/itemgroup/deleteItemgroup`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})
export const ItemgroupMasterSlice = createSlice({
  name: 'ItemgroupMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    Itemgrouplist:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(Itemgrouplist.fulfilled, (state, action) => {
      state.Itemgrouplist = action.payload.Itemgrouplist
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = ItemgroupMasterSlice.actions
export default ItemgroupMasterSlice.reducer
