// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

export const commonData = createAsyncThunk('SupplierMaster/commonData', async (params) => {
    try {
      const response = await axios.post(`${api.api_url}/purchaseManagement/supplierManagement/fetchFilterDataJwt`, params)
      if (response && response.data && response.data.body && response.data.body.token) {
          params['jwtToken'] = response.data.body.token
        const response1 = await axios.post(`${api.api_url}/purchaseManagement/supplierManagement/fetchFilterData`, params)
        console.log(response1, "response1")
        if (response1 && response1.data && response1.data.body) {
          return {
            params,
            StateArray: response1.data.body && response1.data.body.StateArray ? response1.data.body.StateArray : [],
            StatusList: response1.data.body && response1.data.body.StatusArray ? response1.data.body.StatusArray : [],
            AgentList: response1.data.body && response1.data.body.AgentArray ? response1.data.body.AgentArray : [],
            SupplierArray: response1.data.body && response1.data.body.supplierList ? response1.data.body.supplierList : []
            
          }
        } else {
          return {
            StateArray: response1.data.body ? response1.data.body.StateArray : [],
            StatusArray: response1.data.body ? response1.data.body.StatusArray : [],
            AgentList: response1.data.body ? response1.data.body.AgentArray : [],
            SupplierArray: response1.data.body ? response1.data.body.supplierList : []
          }
        }
      } else {
        return {
            StateArray: response.data.body ? response.data.body.StateArray : [],
            StatusArray: response.data.body ? response.data.body.StatusArray : [],
            AgentList: response.data.body ? response.data.body.AgentArray : [],
            SupplierArray: response.data.body ? response.data.body.supplierList : []
        }
      }
    } catch (error) {
      return {
        StateArray: [],
        StatusArray: [],
        AgentList: [],
        SupplierArray: []
      }
    }
  })


export const getSuppliers = createAsyncThunk('SupplierMaster/getSuppliers', async (params) => {
    try {
      const response = await axios.post(`${api.api_url}/purchaseManagement/supplierManagement/getSuppliersJwt`, params)
      if (response && response.data && response.data.body && response.data.body.token) {
          params['jwtToken'] = response.data.body.token
        const response1 = await axios.post(`${api.api_url}/purchaseManagement/supplierManagement/getSuppliers`, params)
        if (response1 && response1.data && response1.data.body) {
          return {
            supplierList: response1.data.body && response1.data.body.supplierList ? response1.data.body.supplierList : [],
            companyArray: response1.data.body && response1.data.body.Company_Array ? response1.data.body.Company_Array : []
            
          }
        } else {
          return {
            supplierList: response1.data.body ? response1.data.body.supplierList : [],
            companyArray: response1.data.body ? response1.data.body.Company_Array : []
          }
        }
      } else {
        return {
            supplierList: response.data.body ? response.data.body.supplierList : [],
            companyArray: response.data.body ? response.data.body.Company_Array : []
        }
      }
    } catch (error) {
      return {
        supplierList: [],
        companyArray: []
      }
    }
  })

  //saveData Api
export const saveData = createAsyncThunk('SupplierMaster/saveData', async params => {
    try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/supplierManagement/saveSupplierManagementJwt`, params)
     if (response && response.data && response.data.body && response.data.body.token) {
        params['jwtToken'] = response.data.body.token
     const response1 = await axios.post(`${api.api_url}/purchaseManagement/supplierManagement/saveSupplierManagement`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
        message:response1.data.body.message,
        SupplierId:response1.data.body.supplier_id,
        SupplierName:response1.data.body.supplier_name
      }
     } else {
      return {
        statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
        message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
        SupplierId:response1.data.body ? response1.data.body.supplier_id : 0,
        SupplierName:response1.data.body ? response1.data.body.supplier_name : ''
      }
     }
    } else {
      return {
        statusFlag:response.data.body ? response.data.body.statusFlag : 2,
        message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later',
        SupplierId:response.data.body ? response.data.body.supplier_id : 0,
        SupplierName:response.data.body ? response.data.body.supplier_name : ''
      }
     }
    } catch (error) {
      return {
        statusFlag:2,
        message:'Server not reachable.Please try again later',
        SupplierId: 0,
        SupplierName: ''
      }    
    }
  })

// CUstomerMaster LIST
export const getSupplierDetails = createAsyncThunk('SupplierMaster/getSupplierDetails', async (params) => {
    try {
      const response = await axios.post(`${api.api_url}/purchaseManagement/supplierManagement/getSupplierDetailsJwt`, params)
      if (response && response.data && response.data.body && response.data.body.token) {
          params['jwtToken'] = response.data.body.token
        const response1 = await axios.post(`${api.api_url}/purchaseManagement/supplierManagement/getSupplierDetails`, params)
        if (response1 && response1.data && response1.data.body) {
          return {
            supplierDetails: response1.data.body && response1.data.body.supplierDetails ? response1.data.body.supplierDetails : []
            
          }
        } else {
          return {
            supplierDetails: response1.data.body ? response1.data.body.supplierDetails : []
          }
        }
      } else {
        return {
            supplierDetails: response.data.body ? response.data.body.supplierDetails : []
        }
      }
    } catch (error) {
      return {
        supplierDetails: []
      }
    }
  })


//updateSupplierJwt Api
export const updateSupplier = createAsyncThunk('SupplierMaster/updateSupplier', async params => {
  try {
  const response = await axios.post(`${api.api_url}/purchaseManagement/supplierManagement/updateSupplierJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/purchaseManagement/supplierManagement/updateSupplier`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'
    }    
  }
})


export const deleteData = createAsyncThunk('SupplierMaster/deleteSupplier', async params => {
    try {
    const response = await axios.post(`${api.api_url}/purchaseManagement/supplierManagement/deleteSupplierJwt`, params)
     if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
     const response1 = await axios.post(`${api.api_url}/purchaseManagement/supplierManagement/deleteSupplier`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
        message:response1.data.body.message
      }
     } else {
      return {
        statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
        message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
      }
     }
    } else {
      return {
        statusFlag:response.data.body ? response.data.body.statusFlag : 2,
        message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
     }
    } catch (error) {
      return {
        statusFlag:2,
        message:'Server not reachable.Please try again later'
      }    
    }
  })

export const SupplierMasterSlice = createSlice({
  name: 'SupplierMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    ColorMasterlist:[],
    statuslist:[],
    StateArray:[],
    statusFlag:0,
    statuscode:0,
    supplierList: [],
    companyArray: [],
    supplierDetails: '',
    StatusList: [],
    SupplierId:'',
    AgentList:[],
    SupplierArray:[],
    SupplierName: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
      state.SupplierId = action.payload.SupplierId
      state.SupplierName = action.payload.SupplierName      
    })
    .addCase(updateSupplier.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(getSuppliers.fulfilled, (state, action) => {
      state.supplierList = action.payload.supplierList
      state.companyArray = action.payload.companyArray      
    })
    .addCase(getSupplierDetails.fulfilled, (state, action) => {
      state.supplierDetails = action.payload.supplierDetails
    })    
    .addCase(commonData.fulfilled, (state, action) => {
        console.log(action.payload)
      state.StateArray = action.payload.StateArray
      state.StatusList = action.payload.StatusList
      state.AgentList = action.payload.AgentList
      state.params = action.payload.params
      state.SupplierArray = action.payload.SupplierArray
      console.log(action.payload)
      console.log(state, "state")
    })
  }
})
export const {
  handleStatusFlag
} = SupplierMasterSlice.actions
export default SupplierMasterSlice.reducer
