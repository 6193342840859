// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


//  GET Common LIST
export const commonData = createAsyncThunk('CompanyMaster/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      StateArray: response1.data.body.StateArray
    }
   } else {
   } 
}
})

// Embroider LIST
export const Embroiderlist = createAsyncThunk('EmbroiderMaster/Materiallist', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/master/embroider/embroiderListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
        params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/master/embroider/embroiderList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          EmbroiderList: response1.data.body && response1.data.body.EmbroiderArray ? response1.data.body.EmbroiderArray : []
          
        }
      } else {
        return {
          EmbroiderList: response1.data.body ? response1.data.body.EmbroiderArray : []
        }
      }
    } else {
      return {
        EmbroiderList: response.data.body ? response.data.body.EmbroiderArray : []
      }
    }
  } catch (error) {
    return {
      EmbroiderList: []
    }
  }
})

//saveData Api
export const saveData = createAsyncThunk('EmbroiderMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/embroider/saveEmbroiderJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/embroider/saveEmbroider`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message:'Server not reachable.Please try again later'
    }
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('EmbroiderMaster/deleteData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/embroider/deleteEmbroiderJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/embroider/deleteEmbroider`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'
    }    
  }
})

export const EmbroiderSlice = createSlice({
  name: 'EmbroiderMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    EmbroiderList:[],
    StateArray:[],
    statusFlag:0,
    statuscode:0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(Embroiderlist.fulfilled, (state, action) => {
      state.EmbroiderList = action.payload.EmbroiderList
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.StateArray = action.payload.StateArray
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = EmbroiderSlice.actions
export default EmbroiderSlice.reducer
